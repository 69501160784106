import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "./CustomDialog";

const LeaderboardModal = ({ open, onClose, title = "Leaderboard", data }) => {
  data.sort((a, b) => {
    if (a.level && b.level) {
      return b.level === a.level ? b.score - a.score : a.level - b.level;
    }
    return b.score - a.score;
  });

  return (
    <CustomDialog open={open} onClose={onClose} title={title}>
      <div className="leaderboard-modal">
        <ul className="leaderboard-list">
          {!data?.length && (
            <div style={{ color: "red" }}>No records found</div>
          )}
          {data.map((row, index) => (
            <li
              key={index}
              className={`leaderboard-item ${
                index === 0
                  ? "first-place"
                  : index === 1
                  ? "second-place"
                  : index === 2
                  ? "third-place"
                  : ""
              }`}
            >
              <div className="rank">#{index + 1}</div>
              <div className="details">
                <h3>{row.playerName}</h3>
                {row.level ? (
                  <p>
                    <span style={{ color: "#0026B2" }}>{row.score}</span> points
                    at level{" "}
                    <span style={{ color: "#aa00b2" }}>{row.level}</span>
                  </p>
                ) : (
                  <p>Score: {row.score}</p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </CustomDialog>
  );
};

export default LeaderboardModal;
