import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "intl-tel-input/build/css/intlTelInput.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getParticipantDashboardByFundRaisingId,
  addNewContact,
  updateContact,
  generateTeammemberInvitationLink,
  generateParticipantGroupDonationLink,
  shareTokensToTeammember,
  deleteContact,
  generateGeneralTeammemberInvitationLink,
} from "../../../apis/Participants";
import CustomTable from "../../common/CustomTable";
import CustomButton from "../../common/CustomButton";
import AddContactModal from "./Modals/AddContactModal";
import { DASHBOARD_MODALS, findMessage, Toaster } from "../../../constants";
import UpdateContactModal from "./Modals/UpdateContactModal";
import InviteTeammemberModal from "./Modals/InviteTeammemberModal";
import TeammemberLinkModal from "./Modals/TeammemberLinkModal";
import copy from "copy-to-clipboard";
import PaymentModal from "../Stripe/PaymentModal";
import TokenSelectorModal from "../../common/TokensSelectorModal";
import ShareTokenModal from "./Modals/ShareTokenModal";
import ConfirmationModal from "../../common/ConfirmationModal";
import Game from "../../common/Game";
import { getAllSponsors } from "../../../apis/Sponsor";
import CopyToClipboard from "react-copy-to-clipboard";

const Info = ({
  type,
  value,
  changeClickAction = null,
  changeButtonText = "Change",
  valueClass = "",
  typeClass = "",
  parentClass = "",
  indicator = false,
  isIndicatorActive = false,
  onToggleIndicator = null,
}) => {
  const [loader, setLoader] = useState(false);
  const handleIndicatorToggle = async () => {
    if (loader) return;
    setLoader(true);
    await onToggleIndicator(!isIndicatorActive);
    setLoader(false);
  };
  return (
    <div className={"info-entity-wrapper " + parentClass}>
      {indicator && (
        <div
          className={"indicator " + (isIndicatorActive ? "active" : "inactive")}
        />
      )}
      <div className="info-entity">
        <div className={"info-type " + typeClass}>{type}:</div>
        <div className={"info-value " + valueClass}>{value}</div>
      </div>
      {changeClickAction && (
        <div className="change-button" onClick={changeClickAction}>
          {changeButtonText}
        </div>
      )}
      {onToggleIndicator && (
        <div
          className={`indicator-change-button ${
            loader ? "loading" : isIndicatorActive ? "deactivate" : "activate"
          }`}
          onClick={handleIndicatorToggle}
        >
          {isIndicatorActive ? "Deactivate" : "Activate"}
        </div>
      )}
    </div>
  );
};

const FundraiserParticipantDashboard = () => {
  const { fundraiserId = null } = useParams();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  if (
    token &&
    (!localStorage.fundToken ||
      localStorage.fundToken !== decodeURIComponent(token))
  ) {
    localStorage.setItem("fundToken", decodeURIComponent(token));

    const newUrl =
      window.location.pathname +
      window.location.search.replace(/[?&]token=[^&]+/, "");
    window.history.replaceState({}, "", newUrl);

    // Refresh the page
    window.location.reload();
  }

  const [participantData, setParticipantData] = useState(null);

  const [sponsors, setSponsors] = useState(null);

  const [activeModal, setActiveModal] = useState(null);

  const [loader, setLoader] = useState(false);

  const [referalLink, setReferalLink] = useState(null);

  const [selectedTeamMembers, selectTeamMembers] = useState([]);

  const navigate = useNavigate();

  const games = participantData?.games;

  let selectedContactIndex = useRef(null);

  const onModalClose = () => setActiveModal({ ...activeModal, type: null });

  const fetchParticipantData = async () => {
    if (!fundraiserId) return;
    const data = await getParticipantDashboardByFundRaisingId(fundraiserId);
    setParticipantData(data);
    if (data?.participantRefferalsCodes)
      setReferalLink(
        process.env.REACT_APP_BASE_URL +
          "donate/" +
          data.participantRefferalsCodes
      );
    if (!data.teamMemberGenernalInvetationLink) {
      generateGeneralTeammemberInvitationLink(data.fundRaiser._id).then(
        (code) => {
          setParticipantData({
            ...data,
            teamMemberGenernalInvetationLink: { invitationCode: code },
          });
        }
      );
    }
  };

  const generateGroupDonationLink = async () => {
    if (loader) return;
    if (referalLink) {
      copy(referalLink);
      return Toaster("success", "Copied to clipboard!");
    }
    setLoader(true);
    const groupId = participantData?.group?._id;
    if (!groupId) {
      setLoader(false);
      return Toaster(
        "warning",
        "You must be in a group to generate referal link!"
      );
    }
    try {
      const id = await generateParticipantGroupDonationLink(groupId);
      setReferalLink(process.env.REACT_APP_BASE_URL + "donate/" + id);
      Toaster("success", "Referal Link generated successfully!");
      setLoader(false);
    } catch {
      setLoader(false);
    }
  };

  const handleAddContact = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const res = await addNewContact(data);
        const updatedContacts = participantData?.contacts;
        updatedContacts.push(res.data);
        setParticipantData({ ...participantData, contacts: updatedContacts });
        Toaster("success", "Contact added successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        setLoader(false);
        Toaster("error", errorMessage || "Error while adding new contact!");
      }
    },
    [participantData]
  );

  const handleInviteTeammember = useCallback(
    async (data) => {
      setLoader(true);
      try {
        data.fundRaisingId = participantData?.fundRaiser?._id;
        let response = await generateTeammemberInvitationLink(data);

        let link;
        if (response.userExists)
          link =
            process.env.REACT_APP_BASE_URL +
            "teammember/dashboard?invitationCode=" +
            response.invitationlink;
        else
          link =
            process.env.REACT_APP_BASE_URL +
            "teammember/signup/" +
            response.invitationlink;

        setLoader(false);
        setActiveModal({ type: DASHBOARD_MODALS.TEAMMEMBER_LINK, link });
      } catch (error) {
        setLoader(false);
        Toaster(
          "error",
          error?.message || "error while generating invitation link"
        );
      }
    },
    [participantData]
  );

  const handleUpdateContact = useCallback(
    async (data) => {
      const { updatedContacts, index } = data;
      setLoader(true);
      try {
        const body = {
          ...updatedContacts[index],
          contactId: updatedContacts[index]._id,
        };
        await updateContact(body);
        setParticipantData({ ...participantData, contacts: updatedContacts });
        Toaster("success", "Contact updated successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        setLoader(false);
        Toaster("error", errorMessage || "Error while adding new contact!");
      }
    },
    [participantData]
  );

  const handleDeleteContact = useCallback(async () => {
    if (selectedContactIndex.current !== 0 && !selectedContactIndex.current)
      return Toaster("error", "Something went while wrong deleting contact!");
    const contactId =
      participantData.contacts[selectedContactIndex.current]._id;
    setLoader(true);
    try {
      await deleteContact(contactId);
      const updatedContacts = participantData.contacts;
      updatedContacts.splice(selectedContactIndex.current, 1);
      setParticipantData({ ...participantData, contacts: updatedContacts });
      Toaster("success", "Contact deleted successfully!");
      setLoader(false);
      setActiveModal(null);
      selectedContactIndex.current = null;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      setLoader(false);
      Toaster("error", errorMessage || "Error while adding deleting contact!");
    }
  }, [participantData, selectedContactIndex]);

  const contactTableCols = [
    { name: "Name", dataIndex: "fullName" },
    { name: "Email", dataIndex: "email" },
    { name: "Phone", dataIndex: "phoneNumber" },
  ];

  const teammemberTableCols = [
    { name: "Name", dataIndex: "fullName" },
    { name: "Email", dataIndex: "email" },
    { name: "Phone", dataIndex: "phoneNo" },
    { name: "Tokens", dataIndex: "tokens" },
  ];

  const contactTableRows = useMemo(
    () => participantData?.contacts || [],
    [participantData]
  );

  const teammemberTableRows = useMemo(() => {
    if (!((participantData?.group?.teamMember?.length || 0) >= 1)) return [];
    return participantData?.group.teamMember.map((teammember) => {
      return {
        ...teammember,
        fullName: teammember.firstName + " " + teammember.lastName,
      };
    });
  }, [participantData]);

  const getParticipantName = () => {
    if (!participantData?.participant?.firstName) return null;
    return (
      participantData.participant?.firstName +
      " " +
      participantData.participant?.lastName
    );
  };

  const handleTokenAmountSelection = (amount) => {
    setActiveModal({ type: DASHBOARD_MODALS.PURCHASE_TOKENS, amount });
  };

  const handleShareTokens = async (tokens) => {
    if (!selectedTeamMembers.length === 1) return;
    setLoader(true);
    try {
      const body = {
        teamMemberId: selectedTeamMembers[0]._id,
        tokensToSend: tokens,
      };
      await shareTokensToTeammember(body);
      setLoader(false);
      setActiveModal(null);
      Toaster("success", "Tokens shared successfully!");
      fetchParticipantData();
    } catch (e) {
      setLoader(false);
      Toaster("error", e?.message || "Error sharing tokens!");
    }
  };

  const fetchSponsors = useCallback(async () => {
    try {
      setLoader(true);
      const res = await getAllSponsors(fundraiserId);
      let sponsorList = res.data.filter(
        (sponsor) => sponsor.type === "business"
      );
      if (sponsorList?.length) sponsorList = sponsorList.reverse();
      setSponsors(sponsorList);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error fetching sponsors!"));
    }
  }, [fundraiserId]);

  const sponsorTableCols = [
    { name: "First Name", dataIndex: "firstName" },
    { name: "Last Name", dataIndex: "lastName" },
    { name: "Business Name", dataIndex: "businessName" },
    { name: "Website Url", dataIndex: "websiteUrl" },
    { name: "Sponsor Status", dataIndex: "sponsorStatus" },
    { name: "Total Donations", dataIndex: "totalTransactions" },
  ];

  const sponsorTableRows = sponsors
    ? sponsors.map((sponsor) => {
        return {
          ...sponsor,
          sponsorStatus: sponsor.isFeatured ? "Sponsored" : "Not Sponsored",
        };
      })
    : [];

  useEffect(() => {
    fetchSponsors();
  }, [fetchSponsors]);

  useEffect(() => {
    fetchParticipantData();
  }, [fundraiserId]);

  const groupId = participantData?.group?._id;

  return (
    <div>
      <CustomButton
        title={"< Back to dashboard"}
        onClick={() => navigate("/participant/dashboard")}
        className={"navigate-button"}
      />
      <div
        className="navigate-text"
        onClick={() => navigate("/participant/dashboard")}
      >
        {"< Back to dashboard"}
      </div>
      <div className="participant-fundraiser-dashboard-wrapper">
        {participantData && (
          <React.Fragment>
            <AddContactModal
              open={activeModal?.type === DASHBOARD_MODALS.ADD_CONTACT}
              onClose={onModalClose}
              onSubmit={handleAddContact}
              loader={loader}
            />
            <UpdateContactModal
              open={activeModal?.type === DASHBOARD_MODALS.UPDATE_CONTACT}
              selectedContact={activeModal?.index}
              onClose={onModalClose}
              onSubmit={handleUpdateContact}
              loader={loader}
              contacts={participantData?.contacts}
            />
            <ConfirmationModal
              title={"Confirm Delete Contact"}
              prompt={"Are you sure you want to delete this contact?"}
              open={activeModal?.type === DASHBOARD_MODALS.DELETE_CONTACT}
              onClose={onModalClose}
              onConfirmation={handleDeleteContact}
              warningOnConfirm
              loader={loader}
            />
            <InviteTeammemberModal
              open={activeModal?.type === DASHBOARD_MODALS.INVITE_TEAMMEMBER}
              onClose={onModalClose}
              onSubmit={handleInviteTeammember}
              loader={loader}
              StartChild={() => (
                <Info
                  type={"Public Invitation Link"}
                  parentClass="scale-down"
                  value={
                    participantData?.teamMemberGenernalInvetationLink
                      ?.invitationCode
                      ? process.env.REACT_APP_BASE_URL +
                        "teammember/signup/" +
                        participantData?.teamMemberGenernalInvetationLink
                          ?.invitationCode
                      : "---"
                  }
                  changeButtonText="copy"
                  changeClickAction={() =>
                    setActiveModal({
                      type: DASHBOARD_MODALS.TEAMMEMBER_LINK,
                      link:
                        process.env.REACT_APP_BASE_URL +
                        "teammember/signup/" +
                        participantData?.teamMemberGenernalInvetationLink
                          ?.invitationCode,
                      isPublicLink: true,
                    })
                  }
                  indicator
                  isIndicatorActive={
                    participantData?.teamMemberGenernalInvetationLink
                      ?.invitationCode
                  }
                  onToggleIndicator={() => {
                    Toaster("error", "Feature in progress...");
                  }}
                />
              )}
              hideForm
            />
            <TeammemberLinkModal
              open={activeModal?.type === DASHBOARD_MODALS.TEAMMEMBER_LINK}
              onClose={onModalClose}
              invitationLink={activeModal?.link}
              isPublicLink={activeModal?.isPublicLink}
            />
            <PaymentModal
              open={activeModal?.type === DASHBOARD_MODALS.PURCHASE_TOKENS}
              onClose={onModalClose}
              title={"Purchase Tokens"}
              tokens={activeModal?.amount}
              fundRaiserId={groupId ? null : fundraiserId}
              groupId={groupId}
            />
            <TokenSelectorModal
              open={
                activeModal?.type === DASHBOARD_MODALS.TOKEN_AMOUNT_SELECTOR
              }
              onClose={onModalClose}
              onSubmit={handleTokenAmountSelection}
            />
            <ShareTokenModal
              open={activeModal?.type === DASHBOARD_MODALS.TOKEN_SHARE}
              onClose={onModalClose}
              loader={loader}
              onSubmit={handleShareTokens}
              maxTokens={participantData?.participant?.tokens || 0}
              teammemberInfo={selectedTeamMembers?.[0]}
            />
          </React.Fragment>
        )}
        <div className="title">Dashboard</div>
        <div className="sections">
          <div className="information-section section">
            <div className="section-title">Information</div>
            <div className="info-group">
              <Info type={"Name"} value={getParticipantName() || "---"} />
              <Info
                type={"Participant ID"}
                value={participantData?.participant?._id || "---"}
              />
              <Info
                type={"Fundraiser"}
                value={participantData?.fundRaiser?.fundRaisingName || "---"}
              />
              <Info
                type={"Fundraiser Goal"}
                value={
                  "$" +
                  (participantData?.fundRaiser?.fundRaisingGoal ||
                    (participantData?.fundRaiser?.fundRaisingGoal === 0
                      ? 0
                      : "---"))
                }
              />
              <Info
                type={"Group"}
                value={participantData?.group?.groupName || "NA"}
              />
            </div>
          </div>
          <div className="donation-section section">
            <div className="section-title">Donation</div>
            <div className="info-group">
              <Info
                type={"Total Donations"}
                value={
                  "$" + (participantData?.participant?.totalDonations || 0)
                }
              />
              <Info
                type={"Cash Earned"}
                value={
                  "$" +
                  (participantData?.participant?.cashEarned ||
                    (participantData?.participant?.cashEarned === 0 ? 0 : 0))
                }
              />
              <Info
                type={"Your Tokens"}
                value={
                  participantData?.participant?.tokens ||
                  (participantData?.participant?.tokens === 0 ? 0 : "--")
                }
              />
              <Info
                type={"Referal Link"}
                value={referalLink || ""}
                changeButtonText={
                  referalLink
                    ? "Copy"
                    : loader
                    ? "Generating..."
                    : "Generate Link"
                }
                changeClickAction={generateGroupDonationLink}
                valueClass="small-text"
              />
            </div>
          </div>
        </div>
        <div className="title">Games</div>
        {!games?.length && (
          <div className="games-info">
            {!games
              ? "Loading Games..."
              : "This fundraiser currently has no games"}
          </div>
        )}
        {games?.length && (
          <div className="games-wrapper">
            {games.map((game) => (
              <Game
                data={game}
                navigate={navigate}
                fundraiserId={fundraiserId}
                blockCompete={
                  !participantData?.participant?.tokens ||
                  !participantData.participant.tokens > 0
                }
              />
            ))}
          </div>
        )}
        {/* <div className="dashboard-img-wrapper">
        <img src={img1} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practice"} className="complete-btn-secondary" />
        </div>
        <img src={img2} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practice"} className="complete-btn-secondary" />
        </div>
        <img src={img3} alt="sponsorImg" className="sponsor-Img" />
        <div className="complete-btn-img-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practice"} className="complete-btn-secondary" />
        </div>
      </div> */}
        {/* <div className="complete-btn-main-wrapper">
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn" />
          <CustomButton title={"Practice"} className="complete-btn" />
        </div>
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn-secondary" />
          <CustomButton title={"Practice"} className="complete-btn-secondary" />
        </div>
        <div className="complete-btn-wrapper">
          <CustomButton title={"Complete"} className="complete-btn-secondary" />
          <CustomButton title={"Practice"} className="complete-btn-secondary" />
        </div>
      </div> */}

        <div className="Purchase-token-btn">
          <CustomButton
            title={"Purchase Token"}
            className="purchase-btn"
            onClick={() =>
              setActiveModal({
                type: DASHBOARD_MODALS.TOKEN_AMOUNT_SELECTOR,
              })
            }
            disabled={!participantData}
          />
        </div>

        <div className="contacts">
          <div className="title">Contacts</div>
          <div className="table">
            <CustomTable
              columns={contactTableCols}
              rows={contactTableRows}
              emptyDisplayText={
                !participantData ? "Loading..." : "You do not have any contacts"
              }
              isCheckBox
              isActions
              onEdit={(contact) => {
                let selectedContact = participantData.contacts.findIndex(
                  (obj) => obj._id === contact._id
                );
                setActiveModal({
                  type: DASHBOARD_MODALS.UPDATE_CONTACT,
                  index: selectedContact,
                });
              }}
              onDelete={(contact) => {
                let contactIndex = participantData.contacts.findIndex(
                  (obj) => obj._id === contact._id
                );
                selectedContactIndex.current = contactIndex;
                setActiveModal({
                  type: DASHBOARD_MODALS.DELETE_CONTACT,
                });
              }}
            />
          </div>
          <div className="button-box-group">
            <div className="button-box">
              <div className="small-button-box-group">
                <CustomButton className="custom-button" title="Send Text" />
                <CustomButton className="custom-button" title="Send Email" />
                {/* <CustomButton
                className="custom-button"
                title="Update Contact"
                disabled={!participantData?.contacts?.length}
                onClick={() => {
                  if (!participantData)
                    return Toaster("warning", "Wait for dashboard to load");
                  setActiveModal({ type: DASHBOARD_MODALS.UPDATE_CONTACT });
                }}
              /> */}
              </div>
              <CustomButton
                className="custom-button"
                title="Add New Contact"
                onClick={() =>
                  setActiveModal({ type: DASHBOARD_MODALS.ADD_CONTACT })
                }
              />
            </div>
            <div className="">Import Contacts</div>
          </div>
        </div>
        <div className="team-members">
          <div className="title">Team Members</div>

          <div className="table">
            <CustomTable
              rows={teammemberTableRows}
              columns={teammemberTableCols}
              isCheckBox
              getCheckedRows={selectTeamMembers}
            />
          </div>
          <div className="button-box-group">
            <div className="button-box">
              <div className="small-button-box-group">
                <CustomButton className="custom-button" title="Send Text" />
                <CustomButton className="custom-button" title="Send Email" />
              </div>
              <CustomButton
                className="custom-button"
                title="Share Tokens"
                disabled={selectedTeamMembers.length === 0}
                onClick={() => {
                  if (selectedTeamMembers.length !== 1)
                    return Toaster(
                      "warning",
                      "You can share tokens to one teammember at a time!"
                    );
                  setActiveModal({ type: DASHBOARD_MODALS.TOKEN_SHARE });
                }}
              />
            </div>
            <div className="button-box">
              <CustomButton
                className="custom-button"
                title="Invite Teammember"
                disabled={!participantData}
                onClick={() => {
                  // if (!participantData?.group?.groupName)
                  //   return Toaster(
                  //     "warning",
                  //     "you must be in a fundraising group to invite new teammember"
                  //   );
                  setActiveModal({ type: DASHBOARD_MODALS.INVITE_TEAMMEMBER });
                }}
              />
            </div>
          </div>
        </div>
        <div className="team-members">
          <div className="title">Fundraiser Sponsors</div>
          <div className="table">
            <CustomTable
              loader={loader}
              rows={sponsorTableRows}
              columns={sponsorTableCols}
              maxHeight={500}
              emptyDisplayText="No sponsors in this fundraising"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserParticipantDashboard;
