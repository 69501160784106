import React, { useState } from "react";
import ".././styles.scss";
import CustomButton from "../../../../common/CustomButton";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../../../../common/CustomDialog";
import { Toaster } from "../../../../../constants";

const LinkModal = ({
  open,
  onClose,
  invitationLink,
  title,
  info,
  logo,
  message,
  isIndicatorActive = null,
  onToggleIndicator,
  setActiveModal = null,
  activeModal,
}) => {
  const [loader, setLoader] = useState(false);
  const handleActivation = async () => {
    setLoader(true);
    try {
      await onToggleIndicator(!isIndicatorActive);
      // Since activeModal is only once updated during modal opening/closing, we need
      // to force it to rerender with updated indicator status inside link modal.
      // It can be done in the definition of onToggleIndicator but we have to then do
      // it multiple times...

      if (setActiveModal)
        setActiveModal({
          ...activeModal,
          isIndicatorActive: !isIndicatorActive,
        });
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const copyToClipboard = async (textOnly = false) => {
    setLoader(true);
    try {
      if (textOnly) {
        await navigator.clipboard.writeText(invitationLink);
      } else {
        if (!logo) throw new Error("Fundraiser logo not set yet!");
        const imageUrl = logo;
        const response = await fetch(imageUrl);

        if (!response.ok) throw new Error("Network response was not ok");

        const blob = await response.blob();
        const file = new File([blob], "logo.png", { type: "image/png" });

        const clipboardItem = new ClipboardItem({
          "image/png": file,
          "text/plain": new Blob([invitationLink], { type: "text/plain" }),
        });

        await navigator.clipboard.write([clipboardItem]);
      }

      Toaster("success", "Invitation copied to clipboard!");
    } catch (err) {
      await navigator.clipboard.writeText(message + "\n" + invitationLink);
      Toaster("success", "Invitation copied to clipboard!");
    } finally {
      setLoader(false);
    }
  };

  return (
    <CustomDialog open={open} onClose={onClose} title={title} closeOnIconOnly>
      <div className="link-modal">
        <div className="info">{info}</div>
        {logo && <img className="logo" src={logo} alt={""} />}
        <div className="message">{message}</div>
        <div className="link-wrapper">
          <div className="invitation-link">{invitationLink}</div>
          {isIndicatorActive !== null && (
            <div
              className={`indicator-change-button ${
                loader
                  ? "loading"
                  : isIndicatorActive
                  ? "deactivate"
                  : "activate"
              }`}
              onClick={handleActivation}
            >
              {isIndicatorActive ? "Deactivate" : "Activate"}
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <CustomButton
            className={"custom-button"}
            title={"Copy Text"}
            onClick={() => copyToClipboard(true)}
            disabled={loader}
          />
          <CustomButton
            className={"custom-button"}
            title={"Copy"}
            onClick={() => copyToClipboard()}
            disabled={loader}
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default LinkModal;
