import { useState } from "react";
import { Toaster } from "../../constants";
import CustomButton from "./CustomButton";
import Leaderboard from "../../assets/leaderboard.svg";
import LeaderboardModal from "./LeaderboardModal";

const LEADERBOARD_STATUS = {
  INACTIVE: 0,
  VISIBLE: 1,
  ACTIVE: 2,
};

const Game = ({ data, fundraiserId, navigate, blockCompete = false }) => {
  const [leaderboardStatus, setLeaderboardStatus] = useState(
    LEADERBOARD_STATUS.INACTIVE
  );

  const showLeaderBoard = () =>
    setLeaderboardStatus(LEADERBOARD_STATUS.VISIBLE);

  const hideLeaderBoard = () =>
    setLeaderboardStatus(LEADERBOARD_STATUS.INACTIVE);

  const openLeaderBoard = () => setLeaderboardStatus(LEADERBOARD_STATUS.ACTIVE);

  const onModalClose = () => setLeaderboardStatus(LEADERBOARD_STATUS.INACTIVE);

  return (
    <div
      className="game"
      onMouseEnter={showLeaderBoard}
      onMouseLeave={hideLeaderBoard}
    >
      <img
        src={Leaderboard}
        alt=""
        className={`leaderboard-image ${
          leaderboardStatus === LEADERBOARD_STATUS.VISIBLE ? "active" : ""
        }`}
        onClick={openLeaderBoard}
      />
      <LeaderboardModal
        open={leaderboardStatus === LEADERBOARD_STATUS.ACTIVE}
        onClose={onModalClose}
        title={data.gameName + " leaderboard"}
        data={data?.leaderBoard?.players || []}
      />
      <img src={data.image} alt="game cover" />
      <div className="name">{data.gameName}</div>
      <div className="desc">{data.gameDescription}</div>
      <div className="flex"></div>
      <CustomButton
        className={"custom-button " + (blockCompete ? "disabled" : "enabled")}
        title={"Compete"}
        onClick={() =>
          blockCompete
            ? Toaster("warning", "You do not have any tokens!")
            : navigate(`/play/${fundraiserId}/${data._id}`)
        }
      />
      <CustomButton
        className={"custom-button"}
        title={"Practice"}
        onClick={() => navigate(`/practice/${fundraiserId}/${data._id}`)}
      />
    </div>
  );
};

export default Game;
